import React from "react";
import Button from "react-bootstrap/Button";

const SlButton = ({ children, className, size, ...rest }) => {
	let buttonClasses;

	if (size === "small") {
		buttonClasses = "p-3 fw-600 cta-btn ";
	} else if (size === "vSmall") {
		buttonClasses = "py-2 px-5 fw-600 cta-btn ";
	} else {
		buttonClasses = "big-button cta-btn";
	}
	if (className) {
		buttonClasses += className;
	}

	return (
		<Button className={buttonClasses} {...rest}>
			{children}
		</Button>
	);
};

export default SlButton;
