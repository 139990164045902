import React from "react";
import { Row, Col } from "react-bootstrap";
import { BsCheckCircle } from "react-icons/bs";
import uuid4 from "uuid4";

const Checklist = ({ items }) => {
	return (
    <>
    {items && items.length > 0 && (
		<Row className="title-container-spacing justify-content-center text-md home-checklist">
			<Col className="d-md-flex  flex-wrap">
        {items.map(item => (
          <span className="pe-md-3 lato fw-700  d-flex align-items-center mt-2">
					<BsCheckCircle className="text-orange text-lg me-2" />
          {item.heading}
				</span>
        ))}
				

			
			</Col>
		</Row>
    )}
    </>
	);
};

export default Checklist;
